import axios from 'axios'
import store from '../store'

export default {
  methods: {
    async getCompanies(
      searchTerm,
      page,
      itemsPerPage,
      channelId,
      channelAuthorizeOnly
    ) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/admin/companies`,
        {
          params: {
            q: searchTerm,
            page: page,
            itemsPerPage: itemsPerPage,
            channelId: channelId,
            channelAuthorizeOnly: channelAuthorizeOnly
          },
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    }
  }
}
