<template>
  <div v-if="mCompany">
    <p class="title">{{ mCompany.Name }}</p>

    <section class="info-tiles">
      <div class="tile is-ancestor has-text-centered">
        <div class="tile is-parent">
          <article class="tile is-child box">
            <p class="title">{{ mCompany.RFM }}</p>
            <p class="subtitle is-size-7">
              {{ $t('Components.Companies.CompanySummary.Subtitle_RFM') }}
            </p>
          </article>
        </div>
        <div class="tile is-parent">
          <article class="tile is-child box">
            <p class="title">{{ mCompany.Segment }}</p>
            <p class="subtitle is-size-7">
              {{ $t('Components.Companies.CompanySummary.Subtitle_Segment') }}
            </p>
          </article>
        </div>
      </div>
    </section>

    <table class="table is-fullwidth is-narrow is-size-7 has-margin-top">
      <tbody>
        <tr>
          <td>{{ $t('Components.Companies.CompanySummary.Table_Address') }}</td>
          <td>{{ mCompany.Name }}</td>
        </tr>
        <tr>
          <td>
            {{ $t('Components.Companies.CompanySummary.Table_Postal-code') }}
          </td>
          <td>{{ mCompany.PostalCode }}</td>
        </tr>
        <tr>
          <td>{{ $t('Components.Companies.CompanySummary.Table_City') }}</td>
          <td>{{ mCompany.City }}</td>
        </tr>
        <tr>
          <td>{{ $t('Components.Companies.CompanySummary.Table_Country') }}</td>
          <td>{{ mCompany.Country }}</td>
        </tr>
        <tr>
          <td>{{ $t('Components.Companies.CompanySummary.Table_Phone') }}</td>
          <td>{{ mCompany.Phone }}</td>
        </tr>
        <tr>
          <td>{{ $t('Components.Companies.CompanySummary.Table_Email') }}</td>
          <td>{{ mCompany.Email }}</td>
        </tr>
      </tbody>
    </table>

    <div>
      <reservation-year-stats
        :companyId="Number(companyId)"
        :locationId="Number(locationId)"
      ></reservation-year-stats>
    </div>

    <div class="has-text-right has-padding-top">
      <a @click="goToCompany" class="button is-small is-success">{{
        $t('Components.Companies.CompanySummary.Button_Go-to-company')
      }}</a>
    </div>

    <div>
      <hr />

      <notes
        :locationId="locationId"
        :type="'company'"
        :itemId="mCompany.Id"
      ></notes>
    </div>
  </div>
</template>

<script>
import companyProvider from '@/providers/company'

const Notes = () => import('@/components/Notes/Notes')
const ReservationYearStats = () =>
  import('@/components/Dashboards/ReservationYearStats')

export default {
  components: {
    notes: Notes,
    'reservation-year-stats': ReservationYearStats,
  },

  props: {
    companyId: {
      default: 0,
      type: Number,
    },

    company: {
      default: null,
      type: Object,
    },

    locationId: {
      default: 0,
      type: Number,
    },
  },

  data() {
    return {
      mCompany: this.company,
    }
  },

  created() {
    if (!this.mCompany) {
      this.getCompanyData()
    }
  },

  beforeDestroy() {
    //this.mCompany = null
  },

  methods: {
    getCompanyData() {
      companyProvider.methods
        .getCompanyById(this.companyId, this.locationId)
        .then((response) => {
          if (response.status === 200) {
            this.mCompany = response.data
          }
        })
    },

    goToCompany() {
      this.$router.push({
        name: `crm-companies-detail`,
        params: { companyId: this.companyId },
      })
    },
  },
}
</script>
